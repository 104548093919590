import TYPES from '@/types';

// Application
import GetIncomeBehaviorInFollowingYearQuery
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/application/queries/get-income-behavior-in-following-year-query';
import GetIncompleteProfileQuery
  from '@/modules/flagship/investor-profile/incomplete-profile/application/queries/get-incomplete-profile-query';
import UpdateIncompleteProfileCommand
  from '@/modules/flagship/investor-profile/incomplete-profile/application/commands/update-incomplete-profile-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  IncomeBehaviorInFollowingYearEntity,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/entities/income-behavior-in-following-year-entity';
import {
  IncompleteProfileDto,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/dtos/incomplete-profile-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipProfilingIncompleteFlowViewModel {
  @Inject(TYPES.GET_INCOME_BEHAVIOR_IN_FOLLOWING_YEAR_QUERY)
  private readonly get_income_behavior_in_following_year_query!:
    GetIncomeBehaviorInFollowingYearQuery;

  @Inject(TYPES.GET_INCOMPLETE_PROFILE_QUERY)
  private readonly get_incomplete_profile_query!: GetIncompleteProfileQuery;

  @Inject(TYPES.UPDATE_INCOMPLETE_PROFILE_COMMAND)
  private readonly update_incomplete_profile_cmd!: UpdateIncompleteProfileCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_incomplete_flow';

  is_open = false;

  income_behavior_next_year_options: Array<IncomeBehaviorInFollowingYearEntity> = [];

  incomplete_profile_dto: IncompleteProfileDto = {
    investor_profile_id: '',
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    is_completed: true,
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadIncompleteProfile();
  }

  closeModal = () => {
    this.is_open = false;
  }

  continue = async () => {
    await this.updateIncompleteProfile();
    this.is_open = false;
  }

  get is_disabled() {
    return !this.incomplete_profile_dto.income_behavior_in_following_year_id;
  }

  loadIncomeBehaviorNextYearOptions = async () => {
    try {
      this.income_behavior_next_year_options = await this
        .get_income_behavior_in_following_year_query.execute();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_income_behavior_next_year_options'),
      );
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.incomplete_profile_dto.investor_profile_id = investor_profile.id;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  loadIncompleteProfile = async () => {
    try {
      const incomplete_profile = await this.get_incomplete_profile_query.execute();
      this.is_open = incomplete_profile.is_incomplete;
      if (this.is_open) {
        await this.loadIncomeBehaviorNextYearOptions();
        await this.loadInvestorProfile();
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_incomplete_profile'),
      );
    }
  }

  updateIncompleteProfile = async () => {
    try {
      await this.update_incomplete_profile_cmd.execute(this.incomplete_profile_dto);
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.update_incomplete_profile'),
      );
    }
  };
}
