






































































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipProfilingIncompleteFlowViewModel from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-incomplete-flow-view-model';

@Component({ name: 'FlagshipProfilingIncompleteFlow' })
export default class FlagshipProfilingIncompleteFlow extends Vue {
  flagship_incomplete_flow_view_model = Vue.observable(
    new FlagshipProfilingIncompleteFlowViewModel(),
  );

  created() {
    this.flagship_incomplete_flow_view_model.initialize();
  }
}
